*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  letter-spacing: 2px;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.5em;
}

h3 {
  font-size: 1.5em;
}

section {
  width: 100%;
  padding: 80px 30px 50px 30px;
}

footer {
  background-color: #343741;
  color: white;
  min-height: 100px;
}

section:nth-child(even) {
  background-color: #f2f3f4;
}

main p {
  font-size: 1.2em;
}

section p {
  font-size: 1.2em;
}

.contact__content p {
  font-size: 1.2em;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 4em;
  }

  main p {
    font-size: 2em;
  }
}

@media only screen and (min-width: 1100px) {
  h1 {
    font-size: 4em;
  }
}

.kanzleiLogo {
  width: 150px;
  z-index: 1;
  cursor: pointer;
}

.logoWrapper a {
  height: 90px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 600px) {
  .kanzleiLogo {
    width: 250px;
  }
}

main {
  width: 100%;
  height: calc(100vh - 90px);
  min-height: 450px;
  position: relative;
  overflow: hidden;
}

.mainBackground {
  object-position: top;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.mainContent {
  position: absolute;
  padding: 0 30px 50px;
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: -90px;
  background-color: rgba(0, 0, 0, .2);
}

.mainContent__link {
  width: 100%;
  margin: 40px 0 0 0;
  text-align: center;
}

@media only screen and (min-width: 1000px) {
  .mainContent__link {
    text-align: right;
  }
}

.mainContent__link a {
  text-decoration: none;
  padding: 15px 25px;
  color: white;
  border: 2px solid white;
  border-radius: 31px;
  font-weight: 900;
  font-size: 1.5em;
}

.contactLink {
  text-decoration: none;
  z-index: 1;
}

.contactLink span {
  display: none;
}

.contactLink img {
  display: inline-block;
  width: 45px;
}

@media only screen and (min-width: 800px) {
  .contactLink {
    padding: 15px 25px;
    color: black;
    border: 2px solid black;
    border-radius: 28px;
    font-weight: bold;
  }

  .contactLink span {
    display: inherit;
  }
  
  .contactLink img {
    display: none;
  }

  .mainContent {
    justify-content: center;
    padding: 0 30px;
    top: 0;
  }

  .mainContent__text {
    max-width: 1000px;
  }
}

#hamburger-icon {
  margin: auto 0;
  display: none;
  cursor: pointer;
  z-index: 1;
}

#hamburger-icon div {
  width: 35px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
  transition: 0.4s;
}

.open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
}

.open .mobile-menu {
  display: block;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 90px;
  left: 0;
  height: calc(100vh - 90px);
  min-height: 500px;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.mobile-menu li {
  list-style: none;
  padding: 20px 20px;
  text-align: center;
}

.mobile-menu li a {
  text-decoration: none;
  color: black;
}

header nav {
  z-index: 1;
}

@media only screen and (max-width: 1650px) {
  header nav {
    display: none;
  }

  #hamburger-icon {
    display: block;
  }
}

.references {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.references__headline, .law__headline, .company__headline, .contact__headline, .imprint__headline, .downloads__headline {
  width: 100%;
  text-align: center;
}

.references__headline h2, .law__headline h2, .company__headline h2, .contact__headline h2, .imprint__headline h2, .downloads__headline h2 {
  margin-bottom: 30px;
}

.reference {
  width: 100%;
  max-width: 1200px;
}

.reference__author {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.2em;
}

.law {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.law__elements {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.law__elements-item {
  width: 100%;
  margin: 0 0 70px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.law__elements-item img {
  width: 5em;
  height: 5em;
  margin: 0 0 30px 0;
}

.law__elements-description {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.law__elements-description p {
  max-width: 1200px;
  padding: 10px 0;
}

@media only screen and (min-width: 1000px) {
  .law__elements-item {
    width: 50%;
  }
}

.company__content {
  width: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
}

.company__content-image, .company__content-text {
  width: 100%;
}

.company__content-text p {
  margin: 0 0 35px 0;
}

.company__content-image img {
  width: 100%;
  margin: 0 0 30px 0;
}

@media only screen and (min-width: 1200px) {
  .company__content-image, .company__content-text {
    width: 45%;
  }

  .company__content-text {
    margin: 0 20px 0 0;
  }

  .company__content-image img {
    margin: 0;
  }

  .company__content {
    align-items: center;
  }
}

.contact__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.contact__content-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 400px;
  padding: 20px 0;
}

.contact__content-item img {
  width: 3em;
  height: 3em;
}

.contact__content-item p {
  min-width: 250px;
}

.contact__content-item a {
  color: black;
}

.downloads__content {
  display: flex;
  justify-content: center;
}

.downloads__content img {
  width: 3em;
  height: 3em;
}

.downloads__content a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 1.2em;
}

.downloads__content span {
  padding: 0 0 0 20px;
}

.imprint button {
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 1.2em;
  text-align: center;
}
.socialMedia {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px
}

.socialMedia img {
  width: 32px;
}

.socialMedia div a:first-child {
  margin: 0 25px 0 0;
}

.imprint__content {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  color: black;
  display: none;
  overflow: auto;
  padding: 0 30px;
}

.imprint__content-close {
  width: 100%;
  text-align: right;
  padding: 15px;
  position: sticky;
  top: 0;
}

.imprint__content-close img {
  width: 35px; 
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0px 5px 10px -10px #111;
  background-color: white;
}

.imprintModal {
  width: 100%;
  text-align: center;
  padding: 0 0 25px 0%;
}

.openImprint {
  display: block;
  z-index: 3;
}

.imprint__content-text p {
  margin: 0 0 20px 0;
}

.imprint__content-text ul {
  padding: 0 30px;
  margin: 0 0 20px 0;
}